import React, { createContext, useContext, useState } from 'react';
import AddSymbolDialog from '../components/add_symbol_dialog';

const SearchDialogContext = createContext();

export function SearchDialogProvider({ children }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openSearchDialog = () => setIsDialogOpen(true);
  const closeSearchDialog = () => setIsDialogOpen(false);

  return (
    <SearchDialogContext.Provider value={{ openSearchDialog }}>
      {children}
      <AddSymbolDialog
        isOpen={isDialogOpen}
        onClose={closeSearchDialog}
        onChange={() => { }}
      />
    </SearchDialogContext.Provider>
  );
}

export function useSearchDialog() {
  return useContext(SearchDialogContext);
}