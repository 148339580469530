import EconomicRelease from './economic_release';

class CPIRelease extends EconomicRelease {
  constructor() {
    const releases = [
      '2024-11-13T08:30:00',
      '2024-12-11T08:30:00',
      '2025-01-15T08:30:00',
      '2025-02-12T08:30:00',
      '2025-03-12T08:30:00',
      '2025-04-10T08:30:00',
      '2025-05-13T08:30:00',
      '2025-06-11T08:30:00',
      '2025-07-15T08:30:00',
      '2025-08-12T08:30:00',
      '2025-09-11T08:30:00',
      '2025-10-15T08:30:00',
      '2025-11-13T08:30:00',
      '2025-12-10T08:30:00',
    ];
    super(releases);
  }
}

export default CPIRelease; 