import React from 'react';
import SlideTransition from './slide_transition';
import { useQuery } from '@tanstack/react-query';
import { market_quote_api_path, show_symbol_path } from '../routes';
import SparklineChart from './sparkline_chart';
import { navigate } from '../navigator';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { getColorClasses } from '../utils/colors';

const IndexLayout = ({ symbol, name, latestPrice, change, changePercent }) => {
  const colors = getColorClasses(change)
  const changeIcon = changePercent >= 0 ? '▲' : '▼'
  const chartColor = changePercent >= 0 ? '#22c55e' : '#ef4444';
  return (
    <div onClick={() => { navigate(show_symbol_path(symbol)) }}
      className={`cursor-pointer transition-colors duration-150 ${colors.background} rounded-lg px-3 py-2`}>
      <div className="text-sky-300 font-semibold text-base">{name}</div>
      <div className={`text-zinc-200`}>{latestPrice}</div>
      <div className=''>
        <SlideTransition>
          <div className={`text-sm ${colors.text}`}>
            {changeIcon} {changePercent}%
          </div>
          <div className={`text-sm ${colors.text}`}>
            {changeIcon} {change}
          </div>
        </SlideTransition>
      </div>
      <div>
        <SparklineChart symbol={symbol} className="h-6" color={chartColor} />
      </div>
    </div>
  );
}

const IndexLayoutMini = ({ symbol, name, latestPrice, change, changePercent }) => {
  const colors = getColorClasses(change)
  const changeIcon = changePercent >= 0 ? '▲' : '▼'
  const prefix = changePercent >= 0 ? '+' : ''
  return <div className="flex flex-row justify-between rounded-lg px-3 py-2 border-zinc-800 border-2">
    <div className="text-sky-300 font-semibold text-base">{name}</div>
    <div className={``}>
      <SlideTransition>
        <span className={`text-base ${colors.text}`}>{changeIcon} {prefix}{changePercent.toFixed(1)}%</span>
        <span className={`text-base ${colors.text}`}>{changeIcon} {prefix}{change}</span>
        <span className={`text-base text-zinc-300`}>{latestPrice.toFixed(2)}</span>
      </SlideTransition>
    </div>
  </div>
}

const MarketIndex = ({ marketIndex }) => {
  const { symbol, name, symbol_link } = marketIndex

  const { data: marketQuoteData, isPending: marketQuotePending } = useQuery({
    queryKey: ["market_quote", symbol],
    queryFn: () => fetch(market_quote_api_path({ symbols: [symbol] })).then(res => res.json())
  });

  if (marketQuotePending) {
    return <div className="rounded-lg px-3 py-2 flex gap-y-1 flex-col bg-zinc-800">
      <div className="text-sky-300 font-semibold text-base">{name}</div>
      <div className="bg-zinc-700 w-16 h-6 animate-pulse rounded-lg" />
      <div className="mt-2 bg-zinc-700 w-full h-10 animate-pulse rounded-lg" />
    </div>
  }

  const { latestPrice, change, changePercent } = marketQuoteData.quotes[symbol]

  return (
    <IndexLayout symbol={symbol_link || symbol} name={name} latestPrice={latestPrice} change={change} changePercent={changePercent} />
  );
};

const MarketIndexWidget = () => {
  const marketIndexes = [{
    symbol: 'SPX',
    name: 'S&P 500',
  }, {
    symbol: 'BTC',
    symbol_link: 'IBIT',
    name: 'Bitcoin',
  }, {
    symbol: '^RUT',
    symbol_link: 'IWM',
    name: 'Russel 2k',
  }, {
    symbol: '^SPGSGC',
    symbol_link: 'GLD',
    name: 'Gold',
  }, {
    symbol: 'CL=F',
    symbol_link: 'USO',
    name: 'Crude Oil',
  }, {
    symbol: 'VIX',
    name: 'VIX',
  },]

  return (<div className="mt-2 flex flex-col">
    <div className="section-header mx-4 mb-2">Market Indexes</div>
    <Swiper
      grabCursor={true}
      pagination={{ clickable: false }}
      spaceBetween={8}
      slidesPerView={3}
      slidesOffsetBefore={16}
      slidesOffsetAfter={16}
      autoplay={{ delay: 5000 }}
      loop={true}
      modules={[Pagination, Autoplay]}
      className="w-full h-fit">
      {marketIndexes.map(marketIndex => (
        <SwiperSlide key={marketIndex.symbol} className=''>
          <MarketIndex marketIndex={marketIndex} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>)
};

export default MarketIndexWidget; 