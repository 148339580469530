import { parse } from 'date-fns';

function parseOptionKey(optionKey) {
  try {
    optionKey = optionKey.replace("O:", ""); // Polygon uses O:<option_symbol> notation
    let optionOccToken = optionKey.split('');

    // Extracting the strike price
    let strikeS = optionOccToken.splice(-8).join('');
    let strike = parseInt(strikeS) / 1000.0;

    // Extracting the option type
    let optionType = optionOccToken.splice(-1)[0] === 'C' ? 'CALL' : 'PUT';

    // Extracting the expiration date
    let expDate = optionOccToken.splice(-6);

    let day = expDate.splice(-2).join('');
    let month = expDate.splice(-2).join('');
    let year = expDate.splice(-2).join('');
    // TODO: fix this hack in 2100
    let expDateFormatted = parse(`20${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
    let ticker = optionOccToken.join('');

    return {
      exp_date: expDateFormatted,
      expires_at: expDateFormatted,
      option_type: optionType,
      strike: strike,
      ticker: ticker
    };
  } catch (e) {
    console.warn(`Failed to parse optionKey = ${optionKey} e=${e}`);
    return null;
  }
}

export { parseOptionKey };