import mixpanel from "mixpanel-browser"
class Tracker {
  static MIXPANEL_INITIALIZED = false;
  static RECENT_EVENTS = new Set();
  static readonly DEBOUNCE_MS = 2000;

  static track(event_name, properties = {}) {
    const eventKey = `${event_name}-${JSON.stringify(properties)}`;

    if (this.RECENT_EVENTS.has(eventKey)) {
      return;
    }

    this.RECENT_EVENTS.add(eventKey);
    setTimeout(() => this.RECENT_EVENTS.delete(eventKey), this.DEBOUNCE_MS);

    if (!this.MIXPANEL_INITIALIZED) {
      mixpanel.init("bce554817ca3f8b9f892043fe8820d6d")
      let distinctId = this.getMetaValue("distinct-id")
      if (distinctId && distinctId !== "") {
        mixpanel.identify(distinctId)
        // console.log(distinctId)
      }
      this.MIXPANEL_INITIALIZED = true
    }
    // console.log(`track ${event_name}`)
    // console.log(properties)
    mixpanel.track(event_name, properties)
  }

  static getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}

export default Tracker