import RangeController from "./range_controller"
import _ from "underscore"
import { ColorType, createChart, CrosshairMode, LineStyle } from 'lightweight-charts'
import MrcEvent from "./mrc_event"
import MrcCache from "./mrc_cache"
import { html, render } from "lit-html"

// Connects to data-controller="price-volatility"
export default class extends RangeController {
  static targets = ['priceChart', 'volChart']

  connect() {
    this.crosshairMove = this.crosshairMove.bind(this)
    this.priceLegendTemplate = this.priceLegendTemplate.bind(this)
    this.volLegendTemplate = this.volLegendTemplate.bind(this)
    this.params = {}

    this.createCharts()
    document.addEventListener(MrcEvent.LOAD("price-volatility-chart"), (event) => {
      this.createCharts()
    })
    super.connect()
  }

  createCharts() {
    this.width = this.priceChartTarget.scrollWidth
    this.height = this.priceChartTarget.scrollHeight
    const chartSettings = this.getChartSettings()
    if (this.priceChart == null && this.height > 100) {
      this.priceChart = createChart(this.priceChartTarget, chartSettings);
    }
    if (this.hasVolChartTarget && this.volChart == null && this.height > 100) {
      chartSettings.height = this.volChartTarget.scrollHeight - 30
      this.volChart = createChart(this.volChartTarget, chartSettings);
    }
    this.populateCharts()
  }

  getChartSettings() {
    return {
      // autoSize: true,
      width: this.width,
      height: this.height,
      layout: {
        background: {type: ColorType.Solid, color: '#000000'},
        textColor: "rgba(255, 255, 255, 0.9)"
      },
      grid: {
        vertLines: {
          color: "rgba(197, 203, 206, 0.0)",
        },
        horzLines: {
          color: "rgba(197, 203, 206, 0.0)"
        }
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: {
          visible: false,
          labelBackgroundColor: "#48e8cc",
        },
        horzLine: {
          visible: true,
          color: '#666666cc',
          style: LineStyle.Solid,
          labelBackgroundColor: '#48e8cc',
        }
      },
      priceScale: {
        borderColor: "rgba(197, 203, 206, 0.8)"
      },
      timeScale: {
        borderColor: "rgba(197, 203, 206, 0.8)",
      }
    };
  }

  populateCharts(params) {
    if (this.priceChart === undefined) {
      return
    }

    let dataUrl = this.element.dataset.dataUrl
    if (!_.isEmpty(params)) {
      const url = new URL(dataUrl, window.location.origin)
      for (const key in params) {
        url.searchParams.set(key, params[key])
      }
      dataUrl = url.href
      this.element.dataset.dataUrl = dataUrl
    }
    this.showLoading()
    // console.log(`Fetching data from ${dataUrl}`)
    MrcCache.fetchContent(dataUrl, response => response.json(), 10000).then((data) => {
      this.populatePriceChart(data);
      this.populateVolchart(data)
      this.priceChart.timeScale().fitContent()
      if (this.priceChart && this.volChart) {
        this.priceChart.timeScale().subscribeVisibleLogicalRangeChange(range => {
          this.volChart.timeScale().setVisibleLogicalRange(range);
        });
        this.volChart.timeScale().subscribeVisibleLogicalRangeChange(range => {
          this.priceChart.timeScale().setVisibleLogicalRange(range)
        });
        this.priceChart.timeScale().subscribeVisibleTimeRangeChange((newVisibleTimeRange) => {
          this.volChart.timeScale().setVisibleRange(newVisibleTimeRange)
        })
        this.volChart.timeScale().subscribeVisibleTimeRangeChange((newVisibleTimeRange) => {
          this.priceChart.timeScale().setVisibleRange(newVisibleTimeRange)
        })
      }
      this.hideLoading()
    })
  }

  populatePriceChart(data) {
    if (this.priceChart === undefined) {
      return
    }
    if (this.candleSeries == null) {
      this.candleSeries = this.priceChart.addCandlestickSeries({
        upColor: '#4bffb5',
        downColor: '#ff4976',
        borderDownColor: '#ff4976',
        borderUpColor: '#4bffb5',
        wickDownColor: '#838ca1',
        wickUpColor: '#838ca1',
      })
    }
    this.priceData = []
    let volumeData = []
    data.prices.forEach((element) => {
      this.priceData.push({
        open: element.open,
        close: element.close,
        previous_close: element.previous_close,
        low: element.low,
        high: element.high,
        time: element.time,
        value: element.open,
      })
      volumeData.push({time: element.time, value: element.volume})
    })
    this.priceData = _.sortBy(this.priceData, (element) => {
      return element.time
    })
    this.candleSeries.setData(this.priceData)

    this.populateSMA(data)

    if (this.volumeSeries == null) {
      this.volumeSeries = this.priceChart.addHistogramSeries({
        color: "rgba(36,189,255,0.65)",
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '', // set as an overlay by setting a blank priceScaleId
      })
    }
    this.volumeSeries.priceScale().applyOptions({
      // set the positioning of the volume series
      scaleMargins: {
        top: 0.90, // highest point of the series will be x% away from the top
        bottom: 0,
      },
    })
    this.volumeSeries.setData(volumeData)
    this.symbol = data.symbol
    this.name = data.name
    this.priceChart.subscribeCrosshairMove(this.crosshairMove)

    if (this.showLegend() !== false)  {
      if (this.priceLegendContainer == null) {
        this.priceLegendContainer = document.createElement("div")
        this.priceLegendContainer.className = "legend-container"
      }
      this.priceChartTarget.prepend(this.priceLegendContainer)
      let smaData = {sma20: _.last(this.smaData["sma20"])}
      let priceData = _.last(this.priceData)
      if (priceData) {
        render(this.priceLegendTemplate({priceData: priceData, smaData: smaData}), this.priceLegendContainer)
      }
    }
  }

  populateSMA(data) {
    if (!this.smaSeries) {
      this.smaSeries = {};
    }
    this.smaData = data.sma
    const allSma = [20]
    this.smaColors = {
      sma20: "rgba(210,96,255,0.8)",
      sma50: "rgba(137,252,42,0.8)",
      sma200: "rgba(107,132,255,0.65)",
    }
    allSma.forEach((sma) => {
      if (this.smaSeries[`sma${sma}`] == null) {
        this.smaSeries[`sma${sma}`] = this.priceChart.addLineSeries({
          color: this.smaColors[`sma${sma}`],
          lineWidth: 1.5,
          lastValueVisible: false,
          priceLineVisible: false,
          crosshairMarkerVisible: false,
        })
      }
      this.smaSeries[`sma${sma}`].setData(this.smaData[`sma${sma}`])
    })
  }

  populateVolchart(data) {
    if (this.volChart === undefined) {
      return
    }

    this.volMetrics = ["atr5", "hv10", "iv"]
    this.volMetricColors = {
      "atr5": "#bb3b3baa",
      "hv10": "#1E90FF99",
      "hv20": "#5D3F6A",
      "iv": "#DAA520",
      "next": "rgb(91,175,85)",
    }
    if (this.volChartData == null) {
      this.volChartData = {}
    }
    this.volMetrics.forEach((volMetric) => {
      let chartOptions = {
        color: this.volMetricColors[volMetric],
        priceLineVisible: false,
        lineWidth: 1.5,
      }
      if (volMetric === "iv") {
        // chartOptions.title = "IV"
        chartOptions.lineWidth = 2
      }
      if (data["vol_series"][volMetric] == null || data["vol_series"][volMetric].length === 0) {
        this.volMetrics = this.volMetrics.filter(function (metric) {
          return metric !== volMetric
        })
        return
      }
      let lineSeries = this.volChartData[volMetric] ? this.volChartData[volMetric].series : this.volChart.addLineSeries(chartOptions)
      this.volChartData[volMetric] = {data: data["vol_series"][volMetric], series: lineSeries}
      lineSeries.setData(data["vol_series"][volMetric])
    })
    this.volChart.timeScale().fitContent()
    let recentDataPoint = {}
    this.volMetrics.forEach((volMetric) => {
      recentDataPoint[volMetric] = _.last(this.volChartData[volMetric].data).value
    })

    if (this.showLegend() !== false)  {
      if (this.volLegendContainer == null) {
        this.volLegendContainer = document.createElement("div")
        this.volLegendContainer.className = "legend-container"
      }
      this.volChartTarget.prepend(this.volLegendContainer)
      render(this.volLegendTemplate({data: recentDataPoint}), this.volLegendContainer)
    }
    this.volChart.subscribeCrosshairMove(this.crosshairMove)
  }

  volLegendTemplate(params) {
    return html`
        <div class="text-base flex flex-col gap-0">
            <div class="-mt-2"><span class="text-zinc-400 text-xs font-semibold uppercase">Volatility</span></div>
            <div class="flex flex-col gap-0">
                ${this.volMetrics.map(volMetric => html`
                    <div class="-mt-1 text-xs rounded-md flex flex-row gap-0 gap-x-1 justify-start items-center">
                        <div class="w-2 h-0.5" style="background: ${this.volMetricColors[volMetric]};"></div>
                        <span class="font-bold uppercase"
                              style="color: ${this.volMetricColors[volMetric]};">${volMetric.toUpperCase()}</span>
                        <span class="text-white">${params.data[volMetric] ? params.data[volMetric].toFixed(1) : "-"}</span>
                    </div>
                `)}
            </div>
        </div>`
  }

  priceLegendTemplate(dataPoint) {
    let previousClose = dataPoint.priceData.previous_close || dataPoint.priceData.close
    let change = (dataPoint.priceData.close - previousClose).toFixed(2)
    // console.log(`previous_close:${dataPoint.priceData.previous_close} close:${dataPoint.priceData.close} change:${change}`)
    if (change > 0) {
      change = "+" + change
    }
    let changePercent = ((change / dataPoint.priceData.open) * 100).toFixed(1)
    let changeColor = change >= 0 ? "text-green-500" : "text-red-500"
    let label = this.name.length <= 8 ? this.name : `${this.name.substring(0, 8)}...`
    return html`
        <div class="text-lg flex flex-col">
            <div class="-mt-1 flex flex-col">
                <span class="text-xs text-zinc-400 font-semibold uppercase">${label}</span>
                <span class="-mt-1 text-sky-300 font-bold">${this.symbol}</span>
                <span class="hidden text-zinc-400">1D</span>
                <span class="-mt-1 flex flex-col gap-0">
                  <span class="-mt-1 text-white">${dataPoint.priceData.close}</span>
                  <span class="-mt-2 text-base ${changeColor}">${change} (${changePercent}%)</span>
                </span>
            </div>
            ${dataPoint.priceData && html`
                <div class="grid grid-cols-2 gap-x-1 text-xs mt-1">
                    <span class="text-zinc-500">O<span
                            class="text-zinc-300 ml-1">${dataPoint.priceData.open.toFixed(2)}</span></span>
                    <span class="text-zinc-500">C<span
                            class="text-white ml-1">${dataPoint.priceData.close.toFixed(2)}</span></span>
                    <span class="text-zinc-500">H<span
                            class="text-green-300 ml-1">${dataPoint.priceData.high.toFixed(2)}</span></span>
                    <span class="text-zinc-500">L<span
                            class="text-red-300 ml-1">${dataPoint.priceData.low.toFixed(2)}</span></span>
                </div>
            `}
            ${dataPoint.smaData && dataPoint.smaData["sma20"] && html`
                <div>
                    ${Object.keys(dataPoint.smaData).map(sma => html`
                        <div class="mt-1 pt-0 border-t border-zinc-800 text-xs flex flex-row gap-0 gap-x-1 justify-start items-center">
                            <div class="w-2 h-0.5" style="background: ${this.smaColors[sma]};"></div>
                            <span class="font-semibold uppercase"
                                  style="color: ${this.smaColors[sma]};">${sma}</span>
                            <span class="text-zinc-300">${dataPoint.smaData[sma] ? dataPoint.smaData[sma].value.toFixed(2) : "-"}</span>
                        </div>
                    `)}
                </div>
            `}
        </div>`
  }

  crosshairMove(param) {
    if (this.pointLineElement == null) {
      this.pointLineElement = document.createElement("div")
      this.pointLineElement.className = "opacity-60 hidden z-10 border-l border-zinc-400 h-full absolute top-0 pointer-events-none"
      this.pointLineElement.style.width = '1px'
      this.priceChartTarget.parentElement.prepend(this.pointLineElement)
    }
    if (param.point) {
      this.pointLineElement.classList.remove('hidden')
      this.pointLineElement.style.left = `${param.point.x}px`
    } else {
      this.pointLineElement.classList.add('hidden')
    }

    const showLegend = this.showLegend()

    if (param.time && showLegend) {
      let dataPoint = {}
      if (this.volChart) {
        this.volMetrics.forEach((volMetric) => {
          let priceDatum = _.find(this.volChartData[volMetric].data, (element) => {
            return element.time === param.time
          })
          if (priceDatum) {
            dataPoint[volMetric] = priceDatum.value
          }
        })
        render(this.volLegendTemplate({data: dataPoint}), this.volLegendContainer)
      }
      let priceDatum = _.find(this.priceData, (element) => {
        return param.time === element.time
      })
      let sma20 = this.smaData ? _.find(this.smaData["sma20"], (element) => {
        return element.time === param.time
      }) : null
      let smaData = {sma20: sma20};
      if (!priceDatum) {
        return
      }
      render(this.priceLegendTemplate({priceData: priceDatum, smaData: smaData}), this.priceLegendContainer)

      let tooltipDistance = 20
      let legendX = param.point.x - 1
      let chartWidth = this.priceLegendContainer.parentElement.scrollWidth
      // console.log(chartWidth)
      let showLeft = param.point.x > (chartWidth * 0.6)
      this.volLegendContainer.style.left = showLeft ? `${legendX - this.volLegendContainer.scrollWidth - tooltipDistance}px` : `${legendX + tooltipDistance}px`;
      this.priceLegendContainer.style.left = showLeft ? `${legendX - this.priceLegendContainer.scrollWidth - tooltipDistance}px` : `${legendX + tooltipDistance}px`;
      let borderWidth = 2
      if (showLeft) {
        this.priceLegendContainer.classList.remove(`border-l-${borderWidth}`)
        this.priceLegendContainer.classList.add(`border-r-${borderWidth}`)
        this.volLegendContainer.classList.remove(`border-l-${borderWidth}`)
        this.volLegendContainer.classList.add(`border-r-${borderWidth}`)
      } else {
        this.priceLegendContainer.classList.remove(`border-r-${borderWidth}`)
        this.priceLegendContainer.classList.add(`border-l-${borderWidth}`)
        this.volLegendContainer.classList.remove(`border-r-${borderWidth}`)
        this.volLegendContainer.classList.add(`border-l-${borderWidth}`)
      }

      // let showLower = param.point.y < (this.priceLegendContainer.parentElement.scrollHeight * 0.5);
      // if (showLower) {
      //   this.priceLegendContainer.style.top = `${param.point.y + tooltipDistance}px`
      // } else {
      //   this.priceLegendContainer.style.top = `${param.point.y - this.priceLegendContainer.scrollHeight - tooltipDistance}px`
      // }
      // this.volLegendContainer.style.top = `${param.point.y}px`
      // this.priceLegendContainer.style.top = `${param.point.y}px`
    }
  }

  showLegend() {
    return !(this.element.getAttribute("data-hide-legend") === "true");
  }
}