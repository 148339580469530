import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeftIcon, PlusIcon, MagnifyingGlassIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from './catalyst/button';
import { show_symbol_path, watchlist_add_api_path, watchlist_remove_api_path } from '../routes';
import { navigate } from '../navigator';
import { useTrendingStocks } from '../hooks/use_trending_stocks';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { motion, AnimatePresence, PanInfo } from 'framer-motion';
import { useStockSearch } from '../hooks/use_stock_search';
import { useWatchlist, WATCHLIST_LIST_QUERY_KEY } from '../hooks/use_watchlist';
import Blaze from '../blaze';
import { useQueryClient } from '@tanstack/react-query';
import Tracker from './tracker';

interface Stock {
  symbol: string;
  name: string;
}

interface AddSymbolDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (symbol: string) => void;
}

interface StockRowProps {
  stock: Stock;
  onChange: (symbol: string) => void;
  onClose: () => void;
  isAdded: boolean;
}

const StockRow: React.FC<StockRowProps> = ({ stock, onChange, onClose, isAdded = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleNavigate = () => {
    navigate(show_symbol_path(stock.symbol));
    onClose();
  };

  const watchlistAdd = (symbol: string) => {
    setIsLoading(true);
    Tracker.track('watchlist.add_symbol', { symbol: symbol });
    fetch(watchlist_add_api_path({ symbols: [symbol] }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
      body: JSON.stringify({ symbol }),
    }).then(response => response.json())
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [WATCHLIST_LIST_QUERY_KEY] });
        onChange(symbol);
      });
  };

  const watchlistRemove = (symbol: string) => {
    setIsLoading(true);
    Tracker.track('watchlist.remove_symbol', { symbol: symbol });
    fetch(watchlist_remove_api_path({ symbols: [symbol] }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
      body: JSON.stringify({ symbol }),
    }).then(response => response.json())
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [WATCHLIST_LIST_QUERY_KEY] });
        onChange(symbol);
      });
  };

  return (
    <div className="flex items-center justify-between py-3 px-0" onClick={handleNavigate}>
      <div className="cursor-pointer flex flex-rowitems-center gap-3">
        <div className="w-10 h-10">
          <img
            src={`/data-img/symbol/${stock.symbol}`}
            alt={stock.name}
            className="w-10 h-10"
          />
        </div>
        <div>
          <div className="text-zinc-100 font-medium">{stock.symbol}</div>
          <div className="text-zinc-400 text-sm truncate max-w-48">{stock.name}</div>
        </div>
      </div>
      {isLoading ? (
        <div className="w-8 h-8 flex items-center justify-center">
          <div className="spinner h-6 w-6" />
        </div>
      ) : isAdded ? (
        <CheckCircleIcon className="w-8 h-8 text-dgreen-400"
          onClick={(e) => {
            e.stopPropagation();
            watchlistRemove(stock.symbol)
          }} />) : (
        <PlusIcon
          className="w-8 h-8 border border-dgreen-400 rounded-full p-1 text-dgreen-400 hover:text-dgreen-300 transition-colors"
          onClick={(e) => {
            e.stopPropagation();
            watchlistAdd(stock.symbol);
          }}
        />
      )}
    </div>
  );
};

const AddSymbolDialog: React.FC<AddSymbolDialogProps> = ({ isOpen, onClose, onChange }) => {
  const { stockResults, searchWithQuery, tickerDataPending } = useStockSearch();
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: trendingResponse, isPending: trendingPending } = useTrendingStocks();
  const { data: watchlistResponse, isPending: watchlistPending, refetch: refetchWatchlist } = useWatchlist();

  const searchTrackTimer = useRef<NodeJS.Timeout>();

  const debouncedTrackSearch = useCallback((query: string) => {
    if (searchTrackTimer.current) {
      clearTimeout(searchTrackTimer.current);
    }

    searchTrackTimer.current = setTimeout(() => {
      Tracker.track('watchlist.search', { query });
    }, 3000);
  }, []);

  useEffect(() => {
    if (isOpen) {
      // setTimeout(() => {
      //   // inputRef.current?.focus();
      //   inputRef.current?.click();
      // }, 100);
      new Promise(resolve => {
        resolve(null);
      }).then(() => {
        // inputRef.current?.focus();
        inputRef.current?.click();
      });
    }
  }, [isOpen]);

  const handleOnChange = () => {
    refetchWatchlist();
    onChange(searchQuery);
  }

  const DialogHeader = () => (
    <div className="flex items-center gap-4 p-4 border-b border-zinc-800">
      <button onClick={onClose} className="text-zinc-400 hover:text-zinc-300">
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <DialogTitle className="text-lg font-semibold text-zinc-100">
        Browse
      </DialogTitle>
    </div>
  );

  const SearchBar = () => (
    <div className="p-4 border-b border-zinc-800">
      <div className="relative flex gap-2 items-center">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            {tickerDataPending ? (
              <div className="spinner h-5 w-5" />
            ) : (
              <MagnifyingGlassIcon className="h-5 w-5 text-zinc-400" />
            )}
          </div>
          <input
            type="search"
            ref={inputRef}
            autoFocus
            className="block w-full pl-10 pr-3 py-2 bg-zinc-800 border border-zinc-700 rounded-lg text-zinc-100 placeholder-zinc-400 focus:outline-none focus:border-dgreen-500 focus:ring-1 focus:ring-dgreen-500"
            placeholder="Search by name or symbol"
            autoComplete="off"
            aria-autocomplete="both"
            autoCorrect="off"
            autoCapitalize="off"
            enterKeyHint="search"
            onClick={() => {
              new Promise(resolve => resolve(null)).then(() => {
                inputRef.current?.focus();
              });
            }}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchWithQuery(e.target.value);
              debouncedTrackSearch(e.target.value);
            }}
          />
        </div>
        <Button plain onClick={onClose} className="text-zinc-400">
          <span className="text-dgreen-400 text-sm font-normal">Done</span>
        </Button>
      </div>
    </div>
  );

  const resultListHeight = 'calc(100vh - 130px)';
  const maxResultsCount = 12;
  const ResultsList = () => (
    <div className="overflow-y-auto px-4" style={{ height: resultListHeight }}>
      {stockResults?.length > 0 ? (
        <div className="divide-y divide-zinc-800">
          {stockResults.slice(0, maxResultsCount).map((stock) => (
            <StockRow
              key={stock.symbol}
              stock={stock}
              isAdded={isWatching(stock)}
              onClose={onClose}
              onChange={handleOnChange}
            />
          ))}
        </div>
      ) : (!searchQuery && (trendingPending || trendingResponse?.trending.length > 0)) ? (
        <div className="divide-y divide-zinc-800 flex flex-col">
          {trendingResponse?.trending.slice(0, maxResultsCount).map((stock) => (
            <div key={stock.symbol}>
              <StockRow
                stock={stock}
                onClose={onClose}
                onChange={handleOnChange}
                isAdded={isWatching(stock)}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex p-4 justify-center items-center">No Results</div>
      )}
    </div>
  );

  return (
    <>
      {isOpen && (
        <Dialog open={true} onClose={onClose} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0">
            <div className="h-full">
              <DialogPanel className="w-full h-full bg-zinc-900">
                <DialogHeader />
                <SearchBar />
                <ResultsList />
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );

  function isWatching(stock: Stock): boolean {
    return !watchlistPending && watchlistResponse?.watchlist.map(w => w.symbol).includes(stock.symbol);
  }
};

export default AddSymbolDialog;