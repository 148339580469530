// Define the formatMoney function
function formatMoney(amount, includePlus = false) {
  const isPositive = amount >= 0;
  const formattedAmount = Math.abs(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const plusOrBlank = includePlus ? "+" : "";
  const cleanedAmount = formattedAmount.replace(/\.00$/, '');
  return isPositive ? `${plusOrBlank}${cleanedAmount}` : `-${cleanedAmount}`;
}

const pctPlus = (num) => {
  const sign = num > 0 ? '+' : '';
  return `${sign}${num.toFixed(2)}`;
};

const formatTime = (timestamp) => {
  try {
    // Convert nanoseconds or milliseconds to milliseconds
    const ts = timestamp > 1e15 ? timestamp / 1e6 : // nanoseconds to milliseconds
              timestamp > 1e12 ? timestamp / 1000 : // microseconds to milliseconds 
              timestamp; // already milliseconds
    const date = new Date(ts);
    // Format time as HH:MM:SS with AM/PM
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  } catch (e) {
    return 'Invalid Time';
  }
};

const capitalize = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
const formatNumber = (num) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`; 
  } else if (num >= 100000) {
    return `${Math.round(num / 1000)}k`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}k`;
  }
  return Math.round(num);
};

// Export the function to be used in other files
export { formatMoney, pctPlus, formatTime, formatNumber, capitalize };