import React, { useRef, useEffect } from 'react';

const PresetSelector = ({ presets, onPresetSelect, filters }) => {
  const selectedClass = 'bg-dgreen-400 text-dgreen-900';
  const unselectedClass = 'bg-zinc-800 text-zinc-300 hover:text-zinc-300';
  const buttonRefs = useRef({});

  // Find matching preset based on filters, default to "Custom" if no match
  const getMatchingPreset = () => {
    const matchingPreset = presets.find(preset =>
      Object.entries(preset.filters).every(([key, value]) =>
        filters[key] === value
      )
    );
    return matchingPreset?.name || 'Custom';
  };

  const effectivePreset = getMatchingPreset();

  // Add useEffect to handle scrolling
  useEffect(() => {
    if (effectivePreset && buttonRefs.current[effectivePreset]) {
      buttonRefs.current[effectivePreset].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [effectivePreset]);

  return (
    <div className="overflow-x-auto no-scrollbar">
      <div className="flex space-x-2 px-2">
        {presets.map(({ name, icon: Icon, filters: presetFilters }) => {
          const buttonClass = effectivePreset === name ? selectedClass : unselectedClass;
          return (
            <button
              key={name}
              ref={el => buttonRefs.current[name] = el}
              onClick={() => onPresetSelect(name, presetFilters)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-full whitespace-nowrap ${buttonClass}`}>
              <Icon className="w-5 h-5" />
              <span className='font-semibold text-sm'>{name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PresetSelector;