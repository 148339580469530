import React from 'react';
import Shortcuts from './shortcuts_component';
import WelcomeHeader from './welcome_header';
import WatchList from './watchlist';
import PortfolioPreview from './portfolio_preview';
import PromoCards from './promo_cards';
import { QueryClientProvider } from '@tanstack/react-query';
import { SearchDialogProvider } from '../contexts/search_dialog_context';

const HomeComponent = () => {
  return (
    <QueryClientProvider client={window.Blaze.getQueryClient()}>
      <SearchDialogProvider>
        <div className="pt-3 md:max-w-lg">
          <WelcomeHeader />
          <PortfolioPreview />
          {/* <Shortcuts />
        <div className='mt-4 flex justify-end'>
          <PromoCards />
        </div> */}
          {/* <div className='section-divider my-2'></div> */}
          <WatchList />
        </div>
      </SearchDialogProvider>
    </QueryClientProvider>
  );
};

export default HomeComponent;
