import React, { useEffect, useState } from 'react';
import { market_trades_path, market_earnings_path, groups_path, robotrader_index_path, gainloss_path } from '../routes';

import {
  CalendarDaysIcon,
  UserGroupIcon,
  CpuChipIcon,
  TrophyIcon,
} from '@heroicons/react/24/solid';

const ShortcutItem = ({ icon: Icon, label, isSelected }) => {
  const selectedClass = isSelected ? 'text-white' : 'text-zinc-400';
  return (
    <div className={`w-full cursor-pointer flex flex-col items-center gap-2 ${selectedClass}`}>
      <div className="w-6 h-6 rounded-full">
        <Icon className="w-full h-full" />
      </div>
      <div className="-mt-1.5 text-xs" style={{ fontSize: '8pt' }}>{label}</div>
    </div>
  );
}

export const submenuItems = [
  // { icon: TrophyIcon, label: 'Trades', path: market_trades_path() },
  { icon: UserGroupIcon, label: 'Groups', path: groups_path() },
  { icon: CpuChipIcon, label: 'Bots', path: robotrader_index_path() },
];

const AppBarSubmenu = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const getSelectedItemFromPath = () => submenuItems.find(item => item.path === window.location.pathname);
  const [selectedItem, setSelectedItem] = useState(getSelectedItemFromPath());

  const handleTouchStart = (e, item) => {
    setCurrentPath(item.path);
    setSelectedItem(item);
    const element = e.currentTarget;
    element.classList.add('expand-in');
    element.addEventListener('animationend', () => {
      element.classList.remove('expand-in');
    }, { once: true });
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
      setSelectedItem(getSelectedItemFromPath());
    };
    document.addEventListener('turbo:visit', handleLocationChange);
    return () => {
      document.removeEventListener('turbo:visit', handleLocationChange);
    };
  }, [currentPath]);

  return (
    <div className="px-1 py-2 border-t border-zinc-800 bg-zinc-900">
      <div className="flex flex-row justify-between">
        {submenuItems.map((item, index) => (
          <a className="w-[80px] flex h-full p-0 m-0 transition-colors duration-200"
            data-turbo-frame="main"
            data-turbo-action="advance"
            href={item.path}
            onTouchStart={(e) => handleTouchStart(e, item)}
            key={index}
            onClick={(e) => handleTouchStart(e, item)}>
            <ShortcutItem {...item} isSelected={selectedItem === item}/>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AppBarSubmenu;