import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { market_trades_api_path } from '../routes';
import { AdjustmentsHorizontalIcon, CurrencyDollarIcon, BellAlertIcon, FireIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import { format, differenceInDays, nextFriday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import TradesTable from './trades_table';
import PresetSelector from './preset_selector';

const TradeFilters = ({ filters, onFilterChange: handleFilterChange }) => {
  const getNextExpirations = (count = 12) => {
    const timeZone = 'America/New_York';
    const today = utcToZonedTime(new Date(), timeZone);
    let currentDate = today;
    const fridays = [];

    while (fridays.length < count) {
      currentDate = nextFriday(currentDate);
      fridays.push(format(currentDate, 'yyyy-MM-dd') + 'T16:00:00');
    }

    return fridays;
  };
  const onFilterChange = (event) => {
    handleFilterChange({ [event.target.name]: event.target.value });
  }

  return (
    <div className="flex flex-col">
      <div className="no-scrollbar overflow-x-auto my-2 flex flex-row md:flex-nowrap gap-x-4 ml-2 md:ml-0">
        <select
          name="calls_or_puts"
          onChange={onFilterChange}
          value={filters.calls_or_puts}
          className="order-select w-32 ml-0">
          <option value="all">Calls & Puts</option>
          <option value="call">Calls</option>
          <option value="put">Puts</option>
        </select>

        <select
          name="side"
          onChange={onFilterChange}
          value={filters.side}
          className="order-select w-28 ml-0">
          <option value="all">Buy & Sell</option>
          <option value="buy">Buy</option>
          <option value="sell">Sell</option>
        </select>

        <select
          name="expiration_date"
          onChange={onFilterChange}
          value={filters.expiration_date}
          className="order-select w-36 ml-0">
          <option value="all">All Expirations</option>
          {getNextExpirations().map(date => {
            const timeZone = 'America/New_York';
            const expirationDate = utcToZonedTime(new Date(date), timeZone);
            const daysToExpiration = differenceInDays(expirationDate, utcToZonedTime(new Date(), timeZone));
            return (
              <option key={date} value={date}>
                {format(expirationDate, 'MMMdd')} ({daysToExpiration}d)
              </option>
            );
          })}
        </select>

        <select
          name="trade_type"
          onChange={onFilterChange}
          value={filters.trade_type}
          className="order-select w-28 ml-0">
          <option value="regular">Regular</option>
          <option value="multi_leg">Multi Leg</option>
          <option value="floor">Floor</option>
        </select>

      </div>
      <div className="mb-2 ml-2 md:ml-1 flex flex-row items-center gap-x-2">
        {!filters.ticker && (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="include_indexes"
              name="include_indexes"
              onChange={(event) => handleFilterChange({ include_indexes: event.target.checked.toString() })}
              checked={filters.include_indexes === 'true'}
              className="cursor-pointer h-4 w-4 rounded border-zinc-500 bg-zinc-800 text-dgreen-600 focus:ring-dgreen-600"
            />
            <label
              htmlFor="include_indexes"
              className={`cursor-pointer ml-2 text-sm ${filters.include_indexes === 'true' ? 'text-dgreen-600' : 'text-gray-500'}`}
            >
              Include Indexes
            </label>
          </div>
        )}
        <div className="flex items-center">
          <input
            type="checkbox"
            id="unusual_only"
            name="unusual_only"
            onChange={(event) => handleFilterChange({ unusual_only: event.target.checked.toString() })}
            checked={filters.unusual_only === 'true'}
            className="cursor-pointer h-4 w-4 rounded border-zinc-500 bg-zinc-800 text-dgreen-600 focus:ring-dgreen-600"
          />
          <label
            htmlFor="unusual_only"
            className={`cursor-pointer ml-2 text-sm ${filters.unusual_only === 'true' ? 'text-dgreen-600' : 'text-gray-500'}`}
          >
            Unusual Only
          </label>
        </div>
      </div>
    </div>
  );
};

const TradesContent = ({ ticker, isLoading, trades, onFilterChange, filters }) => {
  if (isLoading) {
    return <div className="p-4">
      <div className="flex flex-row items-center gap-x-2">
        <div className="w-4 h-4 spinner"></div>
        <span>Loading</span>
      </div>
    </div>;
  }

  if (trades.length === 0) {
    return (
      <div className="whitespace-nowrap p-2 flex flex-row gap-x-2 text-lg text-zinc-400">
        <span>No trades over $10k found.</span>
        {ticker ? <span>See <span className="text-zinc-200">Top Options</span> above for today's option volume.</span>
          : <span>Try adjusting the filters or check back later.</span>}
      </div>
    );
  }

  return (
    <TradesTable trades={trades} onFilterChange={onFilterChange} sortField={filters.sort_field} sortDirection={filters.sort_direction} />
  );
};
const defaultPresets = [
  {
    name: 'Largest Trades',
    icon: CurrencyDollarIcon,
    filters: {
      sort_field: 'notional',
      sort_direction: 'desc',
      unusual_only: 'false',
      trade_type: 'regular',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'all',
      include_indexes: 'false'
    }
  },
  {
    name: 'Unusual Activity',
    icon: BellAlertIcon,
    filters: {
      unusual_only: 'true',
      sort_field: 'notional',
      sort_direction: 'desc',
      trade_type: 'regular',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'all',
      include_indexes: 'false'
    }
  },
  {
    name: 'Custom',
    icon: AdjustmentsHorizontalIcon,
    filters: {
      sort_field: 'notional',
      sort_direction: 'desc',
      unusual_only: 'false',
      trade_type: 'all',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'all',
      include_indexes: 'true'
    }
  }
];

const TradesComponent = ({ ticker = null, showHeader = true, className = 'py-4 flex flex-col md:ml-6 md:max-w-2xl' }) => {
  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const tickerParam = params.get('ticker') || ticker;
    return {
      calls_or_puts: params.get('calls_or_puts') || 'all',
      trade_type: params.get('trade_type') || 'regular',
      expiration_date: params.get('expiration_date') || 'all',
      side: params.get('side') || 'all',
      include_indexes: params.get('include_indexes') || 'false',
      unusual_only: params.get('unusual_only') || 'false',
      ...(tickerParam ? { ticker: tickerParam } : {}),
      sort_field: params.get('sort_field') || 'notional',
      sort_direction: params.get('sort_direction') || 'desc'
    };
  });

  const { data: response = { trades: [] }, isLoading } = useQuery({
    queryKey: ['trades', filters],
    queryFn: () => {
      return fetch(market_trades_api_path(filters)).then(res => res.json());
    },
    refetchInterval: 30000
  });

  const handleFilterChange = (filterChange) => {
    const newFilters = {
      ...filters,
      ...filterChange
    };
    setFilters(newFilters);

    const params = new URLSearchParams(window.location.search);
    Object.entries(newFilters).forEach(([key, value]) => {
      params.set(key, value);
    });
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handlePresetSelect = (presetName, presetFilters) => {
    handleFilterChange(presetFilters);
  };

  const trades = response.trades;
  trades.forEach(trade => {
    trade.chgPct = ((trade.last_quote - trade.price) / trade.price) * 100;
    const chg = trade.last_quote ? (trade.last_quote - trade.price).toFixed(2) : null;
    var chgPct = chg ? ((chg / trade.price) * 100).toFixed(1) : null;
    trade.chgPct = trade.side === 'sell' ? -chgPct : chgPct;
  });
  return (
    <div className={`${className}`}>
      {showHeader && (
        <div>
          <PresetSelector
            presets={defaultPresets}
            onPresetSelect={handlePresetSelect}
            filters={filters}
          />
        </div>
      )}

      <TradeFilters filters={filters} onFilterChange={handleFilterChange} />
      <TradesContent filters={filters} ticker={ticker} onFilterChange={handleFilterChange} isLoading={isLoading} trades={trades} />
    </div>
  );
};

export default function WrappedTradesComponent(props) {
  return (
    <QueryClientProvider client={window.Blaze.getQueryClient()}>
      <TradesComponent ticker={props.ticker} showHeader={props.showHeader} className={props.className} />
    </QueryClientProvider>
  );
}
