import DateCountdown from './date_countdown';

class EconomicRelease {
  constructor(releases) {
    this.releases = releases;
  }

  getNextRelease() {
    return DateCountdown.findNextDate(this.releases);
  }
}

export default EconomicRelease; 