import React, { useState } from 'react';
import { ArrowUpIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';

import { show_option_key_path, show_symbol_path, symbol_image_path } from '../routes';
import { capitalize, formatNumber, formatTime } from '../utils';
import { getColorClasses } from '../utils/colors';

const ExpandIcon = ({ isExpanded }) => (
  <svg
    className={`w-5 h-5 text-zinc-500 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  </svg>
);

const BidAskSpread = ({ width, bid, ask, price, showSpread = false }) => {
  if (!bid || !ask) return null;
  bid = bid / 100;
  ask = ask / 100;
  const spreadWidth = width || 40; // width in pixels
  const spreadRange = ask - bid;
  const markerRadius = 4; // diameter is 8px (w-2 h-2)
  let pricePosition = 0;

  if (spreadRange > 0) {
    pricePosition = ((price - bid) / spreadRange) * (spreadWidth - markerRadius * 2);
    // Add marker radius to offset from left edge
    pricePosition += markerRadius;
    // Clamp position between marker radius and spreadWidth - marker radius
    pricePosition = Math.max(markerRadius, Math.min(pricePosition, spreadWidth - markerRadius));
  }

  return (
    <div className="mt-2 flex flex-col">
      <div className="relative h-1.5 bg-zinc-700 rounded-full" style={{ width: `${spreadWidth}px` }}>
        <div
          className="absolute top-1/2 -translate-y-1/2 w-2 h-2 rounded-full bg-zinc-300"
          style={{ left: `${pricePosition}px`, transform: 'translate(-50%, -50%)' }}
        />
      </div>
      <div className={`${showSpread ? 'flex flex-row' : 'hidden'} justify-between text-xs text-zinc-400 mt-1`} style={{ width: `${spreadWidth}px` }}>
        <div className="-ml-[10px] text-zinc-400">{bid.toFixed(2)}</div>
        <div className="-mr-[10px] text-zinc-400">{ask.toFixed(2)}</div>
      </div>
    </div>
  );
};

const ExpandedDetails = ({ trade }) => (
  <div className="mt-3 mb-2 text-sm" data-id={trade.id}>
    <div className="grid grid-cols-5 gap-x-2 items-start">
      <div>
        <div className="text-zinc-400 mb-1">Size</div>
        <div className="text-sky-300">{formatNumber(trade.size)}</div>
      </div>
      <div>
        <div className="text-zinc-400 mb-1">OI</div>
        <div className="text-zinc-300">{formatNumber(trade.open_interest)}</div>
      </div>
      {trade.condition_description && (
        <div className="col-span-1">
          <div className="text-zinc-400 mb-1">Type</div>
          <div className="text-white">{trade.condition_description}</div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="text-zinc-400 mb-1">Fill Price</div>
        <div className="text-yellow-400 ">
          {trade.price.toFixed(2)}
        </div>
        <BidAskSpread
          bid={trade.bid_cents}
          ask={trade.ask_cents}
          price={trade.price}
          showSpread={false}
        />
      </div>
      <div>
        <div className="text-zinc-400 mb-1">Last</div>
        <div className="text-white">{trade.last_quote}</div>
      </div>
    </div>
  </div>
);

const TradeSide = ({ side }) => {
  const sideColor = !side ? 'bg-zinc-400/20 text-zinc-400' :
    ["buy", "mid"].includes(side) ? 'bg-green-400/20 text-green-400' : 'bg-rose-400/20 text-rose-400';
  return <span className={`w-[35px] text-center mr-1 md:mr-2 px-1.5 py-0.5 rounded text-sm ${sideColor}`}>
    {side ? side === "sell" ? "Sell" : 'Buy' : '-'}
  </span>;
};

const TradeCard = ({ trade, maxNotional }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const chgColor = getColorClasses(trade.chgPct).text;
  const notionalWidth = (trade.notional / maxNotional) * 100;

  return (
    <div className="bg-black border-b border-l border-r border-zinc-800 md:px-2">
      <div
        className="py-3 px-1 md:px-2 active:bg-zinc-900"
        onClick={() => setIsExpanded(!isExpanded)}>

        <div className="flex flex-row items-start justify-between mb-0">
          <div className="flex flex-row items-start">
            <div className="hidden md:block md:mr-2 text-zinc-400 w-[64px] mt-[1px] text-sm">{formatTime(trade.sip_timestamp)}</div>
            <TradeSide side={trade.side} />
            <div className="relative font-semibold min-w-[50px] w-[55px] mr-1 md:mr-2 px-1.5 py-0.5 rounded text-sm">
              <div
                className="absolute inset-y-0 left-0 bg-violet-400/30 rounded"
                style={{ width: `${notionalWidth}%` }}
              />
              <span className="relative z-10 text-violet-200">${formatNumber(trade.notional)}</span>
            </div>
            <div className="flex flex-row items-start gap-x-1">
              <a href={show_symbol_path(trade.ticker)}
                data-turbo-action="advance"
                data-turbo-frame="main"
                className="flex flex-row items-center gap-x-1 text-base text-sky-400 font-normal">
                <div>
                  <img src={symbol_image_path(trade.ticker)} className="min-w-5 min-h-5 w-5 h-5 rounded-full" />
                </div>
                <div className="font-medium">{trade.ticker}</div>
              </a>
              <a href={show_option_key_path(trade.symbol)}
                data-turbo-action="advance"
                data-turbo-frame="main"
                className="text-base text-zinc-200 font-normal">
                {trade.description?.split(' ').slice(1).join(' ')}
              </a>
            </div>
          </div>
          <div className="flex flex-row items-start space-x-1 md:space-x-2">
            <div className={`px-1 rounded text-sm  text-slate-400`}>
              <div className="text-base">{trade.price.toFixed(2)}</div>
            </div>
            <div className="hidden px-1 w-[30px] md:flex items-end">
              <BidAskSpread
                bid={trade.bid_cents}
                ask={trade.ask_cents}
                price={trade.price}
                showSpread={false}
                width={30}
              />
            </div>
            <div className={`${chgColor} w-[52px] text-base text-right font-medium`}>
              {trade.chgPct > 0 ? '+' : ''}{trade.chgPct}%
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-between mt-2 md:hidden'>
          <div className="text-zinc-400 text-sm">{formatTime(trade.sip_timestamp)}</div>
          <ExpandIcon isExpanded={isExpanded} />
        </div>
        {isExpanded && <ExpandedDetails trade={trade} />}
      </div>
    </div>
  );
};

const TableHeader = ({ sortField, sortDirection, onSort }) => {
  const columnClasses = "text-zinc-400 text-xs font-medium flex items-center cursor-pointer";

  const renderSortIcon = (field) => {
    if (sortField === field) {
      return <ArrowUpIcon className={`w-3 h-3 ml-0.5 ${sortDirection === 'desc' && 'rotate-180'}`} />;
    }
    return <ChevronUpDownIcon className="w-4 h-4 text-zinc-400" aria-hidden="true" />;
  };

  return (
    <div className="sticky top-0 w-full flex flex-row justify-between bg-zinc-900 border-b border-zinc-800 py-2 px-1 md:px-2 gap-x-4">
      <div className="flex flex-row items-center md:gap-x-1 md:px-2">
        <div className={`${columnClasses} w-[43px] md:w-[108px]`} onClick={() => onSort('time')}>
          Time
          {renderSortIcon('time')}
        </div>
        <div className={`${columnClasses}`} onClick={() => onSort('premium')}>
          Premium
          {renderSortIcon('premium')}
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-1">
        <div className={`${columnClasses}`} onClick={() => onSort('price')}>
          Price
          {renderSortIcon('price')}
        </div>
        <div className={`${columnClasses} hidden md:block`}>
          Spread
        </div>
        <div className={`${columnClasses}`} onClick={() => onSort('change')}>
          Change
          {renderSortIcon('change')}
        </div>
      </div>
    </div>
  );
};

const TradesTable = ({ trades, onFilterChange, sortField: initialSortField, sortDirection: initialSortDirection }) => {
  const [sortField, setSortField] = useState(initialSortField || 'premium');
  const [sortDirection, setSortDirection] = useState(initialSortDirection || 'desc');

  const maxNotional = Math.max(...trades.map(trade => trade.notional));

  const handleSort = (field) => {
    const newDirection = sortField === field
      ? (sortDirection === 'asc' ? 'desc' : 'asc')
      : 'desc';

    setSortField(field);
    setSortDirection(newDirection);
    onFilterChange({
      sort_field: field,
      sort_direction: newDirection
    });
  };

  const sortedTrades = [...trades].sort((a, b) => {
    if (sortField === 'time') {
      return sortDirection === 'asc'
        ? a.sip_timestamp - b.sip_timestamp
        : b.sip_timestamp - a.sip_timestamp;
    } else if (sortField === 'change') {
      return sortDirection === 'asc'
        ? a.chgPct - b.chgPct
        : b.chgPct - a.chgPct;
    } else if (sortField === 'price') {
      return sortDirection === 'asc'
        ? a.price - b.price
        : b.price - a.price;
    } else if (sortField === 'premium') {
      return sortDirection === 'asc'
        ? a.notional - b.notional
        : b.notional - a.notional;
    }
    return 0;
  });

  return (
    <div className="flex flex-col">
      <TableHeader
        sortField={sortField}
        sortDirection={sortDirection}
        onSort={handleSort}
      />
      {sortedTrades.map(trade => (
        <TradeCard
          key={trade.id}
          trade={trade}
          maxNotional={maxNotional}
        />
      ))}
    </div>
  );
};

export default TradesTable;