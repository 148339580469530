import React from 'react';
import { SearchDialogProvider } from '../contexts/search_dialog_context';
import { QueryClientProvider } from '@tanstack/react-query';
import SearchInput from './search_input';

const SearchInputWithDialog = (props) => (
  <QueryClientProvider client={window.Blaze.getQueryClient()}>
    <SearchDialogProvider>
      <SearchInput {...props} />
    </SearchDialogProvider>
  </QueryClientProvider>
);

export default SearchInputWithDialog;