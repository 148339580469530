import React from 'react';
import MarketSectors from './market_sectors';
import { TopStocksWidget } from './stock_widget';
import MarketIndexWidget from './market_index_widget';
import SearchInput from './search_input';
import { QueryClientProvider } from '@tanstack/react-query';
import UpcomingEarnings from './upcoming_earnings';
import { SearchDialogProvider } from '../contexts/search_dialog_context';

const ExploreComponent = () => {
  return (
    <QueryClientProvider client={window.Blaze.getQueryClient()}>
      <SearchDialogProvider>
        <div className="py-4 flex flex-col md:max-w-lg">
          <div className='px-4'>
            <h1 className='text-xl text-zinc-300 font-semibold'>Explore Markets</h1>
          </div>
          <div className='mx-4 mt-2 mb-2'>
            <SearchInput />
          </div>
          <MarketIndexWidget />
          <TopStocksWidget />
          <UpcomingEarnings />
          <MarketSectors />
        </div>
      </SearchDialogProvider>
    </QueryClientProvider>
  );
};

export default ExploreComponent;
