import EconomicRelease from './economic_release';

class PPIRelease extends EconomicRelease {
  constructor() {
    const releases = [
      '2024-11-14T08:30:00',
      '2024-12-12T08:30:00',
      '2025-01-14T08:30:00',
      '2025-02-13T08:30:00',
      '2025-03-13T08:30:00',
      '2025-04-11T08:30:00',
      '2025-05-15T08:30:00',
      '2025-06-12T08:30:00',
      '2025-07-16T08:30:00',
      '2025-08-14T08:30:00',
      '2025-09-10T08:30:00',
      '2025-10-16T08:30:00',
      '2025-11-14T08:30:00',
      '2025-12-11T08:30:00',
    ];
    super(releases);
  }
}

export default PPIRelease; 