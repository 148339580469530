import { Controller } from "@hotwired/stimulus"
import React from "react"
import { createRoot } from 'react-dom/client';
import ExploreComponent from "../components/explore_component";
import TradesComponent from "../components/trades_component";
import SearchInputWithDialog from "../components/search_input_with_dialog";

const COMPONENTS = {
  'ExploreComponent': ExploreComponent,
  'TradesComponent': TradesComponent,
  'SearchInputWithDialog': SearchInputWithDialog,
};

// Connects to data-controller="react"
export default class extends Controller {
  static values = {
    component: String,
    props: Object
  }

  connect() {
    const ComponentClass = COMPONENTS[this.componentValue];

    if (!ComponentClass) {
      console.error(`Component ${this.componentValue} not found`);
      return;
    }

    this.root = createRoot(this.element);
    this.root.render(
      <ComponentClass {...this.propsValue} />
    );
  }

  disconnect() {
    this.root?.unmount();
  }
}