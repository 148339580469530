import React, { useState } from 'react';
import { Stock } from '../types/stock';
import SlideTransition from './slide_transition';

const getIVCost = (stock: Stock): number => {
  const ratio = stock.iv_edge;
  // Return 1-3 $ signs based on how expensive IV is compared to the move
  if (ratio < 0.5) return 3;  // Very expensive (actual move is much smaller than expected)
  if (ratio < 1) return 2;    // Moderately expensive
  return 1;                   // Cheap IV (actual move is larger than expected)
}


const VolatilityInfo = ({ stock }: { stock: Stock }) => {
  const dollarSigns = getIVCost(stock);
  const color = dollarSigns >= 3 ? 'text-red-300' : dollarSigns === 2 ? 'text-yellow-400' : 'text-emerald-200';
  return <div className="mt-[2px] w-[60px] pr-2 text-right text-zinc-500 text-[12pt] truncate">
    {stock.iv30 ? (
      <div className="flex flex-col items-end justify-end">
        <span className="text-base text-yellow-500">{stock.iv30.toFixed(0)} iv</span>
        <div className={`text-xs flex flex-row items-center ${color}`}>
          {stock.iv_edge && dollarSigns == 1  && <span className={`opacity-40 mr-1 text-xs ${color}`}>{stock.iv_edge.toFixed(1)}x</span>}
          <span>{Array(dollarSigns).fill('$').join('')}</span>
        </div>
      </div>
    ) : '-'}
  </div>
}

export { VolatilityInfo, getIVCost };
